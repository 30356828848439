<template>
  <WsMain class="search-result">
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <WsText
      size="26"
      class="mt-18"
    >'{{_searchText}}' has {{searchResultNum}} results.</WsText>
    <WsSearchResultItem
      v-for="(item, index) in items"
      :key="index"
      :title="item.title"
      :linkTo="item.linkTo"
      :breadcrumbs="item.breadcrumbs"
      :content="item.content"
    ></WsSearchResultItem>
    <WsDataTablePagenateTypeB
      v-if="paginate"
      @pageto="$_onPageto($event)"
      :currentPage="currentPage"
      :dataTotalCount="dataTotalCount"
      :itemsPerPage="itemsPerPage"
      :lastPage="lastPage"
    ></WsDataTablePagenateTypeB>
  </WsMain>
</template>
<script>
export default {
  name: "SearchResult",
  data: () => ({
    searchResultNum: 460,
    paginate: false,
    currentPage: 1,
    dataTotalCount: 18,
    lastPage: 3,
    itemsPerPage: 15,
    items: [
      {
        title:
          "Messaging API released and Wasateam Developers documentation updated",
        linkTo: "/",
        breadcrumbs: [{ text: "aaa" }, { text: "aaa" }, { text: "aaa" }],
        content:
          "Login,SDK for Android, SDK for iOS, Web API, and Social REST API are included in Social API Added information on Official Accounts",
      },
      {
        title:
          "Messaging API released and Wasateam Developers documentation updated",
        linkTo: "/",
        breadcrumbs: [{ text: "aaa" }, { text: "aaa" }, { text: "aaa" }],
        content:
          "Login,SDK for Android, SDK for iOS, Web API, and Social REST API are included in Social API Added information on Official Accounts",
      },
      {
        title:
          "Messaging API released and Wasateam Developers documentation updated",
        linkTo: "/",
        breadcrumbs: [{ text: "aaa" }, { text: "aaa" }, { text: "aaa" }],
        content:
          "Login,SDK for Android, SDK for iOS, Web API, and Social REST API are included in Social API Added information on Official Accounts",
      },
    ],
  }),
  computed: {
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t("Home"),
        to: "/",
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this.$t("Search"),
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _searchText() {
      return "API";
    },
  },
  methods: {
    $_onPageto($event) {
      this.page = $event;
      this.fetchData();
    },
  },
};
</script>